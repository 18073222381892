import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { backgroundColor, text } from '../theme';
import { BodyHeader } from './BodyHeader';
import BodyPaper from './BodyPaper';
import Footer from './Footer';
import TooltipOrFragment from './Tooltip-Or-Fragment';

const StyledBodyPaper = styled(BodyPaper)`
  padding: 0;
`;

const Content = styled.div`
  flex: 1;
`;

const navigationWidth = css`
  width: 300px;

  ${({ theme }) => `${theme.breakpoints.down('md')} {
      width: 56px;
    }
  `}
`;

const NavigationContainer = styled(Stack)`
  ${navigationWidth}
`;

const ListHeaderSpacer = styled.div`
  ${navigationWidth}
`;

const PageTitle = styled(Typography).attrs(() => ({ variant: 'h5' }))`
  color: ${text.secondary};
  flex: 1;
  margin-bottom: 16px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
`;

export const ContentContainer = styled.div`
  padding: 40px;
`;

export const ErrorContainer = styled(ContentContainer)`
  background-color: ${backgroundColor};
  padding-top: 1px;
`;

const PageWithSideNavigation = ({ children, navigationItems, titleItems }) => {
  const { pathname } = useLocation();

  const isSmallBreakpoint = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { path, title } = titleItems.find((titleItem) => matchPath(titleItem.path, pathname));

  return (
    <>
      <BodyHeader>
        <Stack direction='row'>
          <ListHeaderSpacer />
          <PageTitle key={path}>{title}</PageTitle>
        </Stack>
      </BodyHeader>
      <StyledBodyPaper $shouldHideTopMargin>
        <Stack direction='row'>
          <NavigationContainer>
            {navigationItems.map(({ links, subheader }, index) => (
              <Fragment key={subheader}>
                {index > 0 && <Divider />}
                <List
                  subheader={
                    !isSmallBreakpoint ? <ListSubheader>{subheader}</ListSubheader> : undefined
                  }
                >
                  {links.map(({ Icon, label, to }) => (
                    <ListItemButton
                      component={Link}
                      key={label}
                      selected={!!matchPath(to, pathname)}
                      to={to}
                    >
                      <TooltipOrFragment title={isSmallBreakpoint ? label : undefined}>
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                      </TooltipOrFragment>
                      {!isSmallBreakpoint && <ListItemText>{label}</ListItemText>}
                    </ListItemButton>
                  ))}
                </List>
              </Fragment>
            ))}
          </NavigationContainer>
          <Divider flexItem orientation='vertical' />
          <Content>{children}</Content>
        </Stack>
      </StyledBodyPaper>
      <Footer />
    </>
  );
};

PageWithSideNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      links: PropTypes.arrayOf(
        PropTypes.shape({
          Icon: PropTypes.elementType.isRequired,
          label: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        }),
      ),
      subheader: PropTypes.string.isRequired,
    }),
  ).isRequired,
  titleItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PageWithSideNavigation;
