import { ArrowDropDown, DeleteForever, Edit, OpenInFull } from '@mui/icons-material';
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import useTenants from '../shared/api/use-tenants';
import Button from '../shared/components/Button';
import FetchError from '../shared/components/Fetch-Error';
import {
  ActionBar,
  DrawerContainer,
  DrawerInnerContainer,
} from '../shared/components/Page-With-Side-Sheet';
import { SkeletonIfLoading, TextSkeletonIfLoading } from '../shared/components/Skeleton';
import useDependentRequests from '../shared/hooks/use-dependent-requests';
import { text } from '../shared/theme';
import DeleteTenant from './Delete-Tenant';
import TenantDetailsNotSelected from './Tenant-Details-Not-Selected';
import {
  DELETE_TENANT_BUTTON_TEXT,
  EDIT_TENANT_BUTTON_TEXT,
  FETCH_TENANT_ERROR_DISPLAY_TEXT,
  MORE_ACTIONS_BUTTON_TEXT,
  OPEN_TENANT_BUTTON_TEXT,
  TENANT_DETAILS_CONTAINER_TEST_ID,
} from './constants';

const StyledDrawerContainer = styled(DrawerContainer)`
  position: relative;
  width: 520px;
`;

const TenantName = styled(Typography).attrs(() => ({ variant: 'h5' }))`
  color: ${text.primary};
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
`;

const TenantOrgCode = styled(Typography).attrs(() => ({ variant: 'body2' }))`
  color: ${text.secondary};
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
`;

const TenantId = styled(Typography).attrs(() => ({ variant: 'body2' }))`
  color: ${text.secondary};
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
`;

const InlineDrawer = ({ handleEditTenant, open, selectedTenantId }) => {
  const { ref: innerContainerRef } = useResizeObserver();

  const [isDeleteTenantOpen, setIsDeleteTenantOpen] = useState(false);

  const handleOpenDeleteTenant = () => setIsDeleteTenantOpen(true);
  const handleCloseDeleteTenant = () => setIsDeleteTenantOpen(false);

  const tenantsResult = useTenants();

  const { tenantsById } = tenantsResult;

  const tenant = tenantsById?.[selectedTenantId];

  const { isError, isLoading, refetch } = useDependentRequests([tenantsResult]);

  const [moreActionsAnchorElement, setMoreActionsAnchorElement] = useState(null);

  const handleCloseMoreActionsMenu = () => setMoreActionsAnchorElement(null);

  const handleOpenMoreActionsMenu = (event) => {
    setMoreActionsAnchorElement(event.currentTarget);
  };

  const handleSelectActionFromMenu = () => {
    handleCloseMoreActionsMenu();
  };

  const moreTenantActions = [
    {
      action: () => handleEditTenant(selectedTenantId),
      label: EDIT_TENANT_BUTTON_TEXT,
      ActionIcon: Edit,
    },
    {
      action: handleOpenDeleteTenant,
      label: DELETE_TENANT_BUTTON_TEXT,
      ActionIcon: DeleteForever,
    },
  ];

  return (
    <>
      <DeleteTenant
        handleClose={handleCloseDeleteTenant}
        open={isDeleteTenantOpen}
        tenant={tenant}
      />
      <Collapse in={open} orientation='horizontal'>
        <StyledDrawerContainer
          $whiteBackground={!!selectedTenantId && !isError}
          data-testid={TENANT_DETAILS_CONTAINER_TEST_ID}
        >
          {!selectedTenantId && <TenantDetailsNotSelected />}
          {selectedTenantId && isError && (
            <FetchError errorMessage={FETCH_TENANT_ERROR_DISPLAY_TEXT} refetch={refetch} />
          )}
          {selectedTenantId && !isError && (
            <>
              <DrawerInnerContainer ref={innerContainerRef}>
                <TenantName>
                  <TextSkeletonIfLoading isLoading={isLoading}>
                    {tenant?.name}
                  </TextSkeletonIfLoading>
                </TenantName>
                <TenantOrgCode>
                  <TextSkeletonIfLoading isLoading={isLoading}>
                    {tenant?.orgCode}
                  </TextSkeletonIfLoading>
                </TenantOrgCode>
                <TenantId>
                  <TextSkeletonIfLoading isLoading={isLoading}>{tenant?.id}</TextSkeletonIfLoading>
                </TenantId>
              </DrawerInnerContainer>
              <ActionBar>
                <Stack direction='row' spacing={2}>
                  <SkeletonIfLoading isLoading={isLoading}>
                    <Button
                      component={Link}
                      to={`/tenants/${selectedTenantId}/tenant-setup/tenant-details`}
                      size='small'
                      startIcon={<OpenInFull />}
                      variant='contained'
                    >
                      {OPEN_TENANT_BUTTON_TEXT}
                    </Button>
                  </SkeletonIfLoading>
                </Stack>
                <SkeletonIfLoading isLoading={isLoading}>
                  <Button
                    endIcon={<ArrowDropDown />}
                    onClick={handleOpenMoreActionsMenu}
                    size='small'
                  >
                    {MORE_ACTIONS_BUTTON_TEXT}
                  </Button>
                </SkeletonIfLoading>
                <Menu
                  anchorEl={moreActionsAnchorElement}
                  onClose={handleCloseMoreActionsMenu}
                  open={!!moreActionsAnchorElement}
                >
                  {moreTenantActions.map(({ action, label, ActionIcon }) => (
                    <MenuItem
                      key={label}
                      onClick={(event) => {
                        action(event);
                        handleSelectActionFromMenu();
                      }}
                    >
                      <ListItemIcon>
                        <ActionIcon />
                      </ListItemIcon>
                      <ListItemText>{label}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </ActionBar>
            </>
          )}
        </StyledDrawerContainer>
      </Collapse>
    </>
  );
};

InlineDrawer.propTypes = {
  handleEditTenant: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedTenantId: PropTypes.string,
};

InlineDrawer.defaultProps = {
  selectedTenantId: null,
};

export default InlineDrawer;
