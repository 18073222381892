import get from 'lodash.get';
import { useMemo, useState } from 'react';

import { ASCENDING_KEY, DESCENDING_KEY } from '../../constants/sorting';

const equalFunc = () => 0;

const useSort = ({ defaultSort, idToSortMap, items }) => {
  const [sort, setSort] = useState(defaultSort);

  const createSortHandler = (id) => () => {
    setSort({
      id,
      order: sort.order === ASCENDING_KEY && sort.id === id ? DESCENDING_KEY : ASCENDING_KEY,
    });
  };

  const sortedItems = useMemo(
    () =>
      items?.slice()?.sort((a, b) =>
        (idToSortMap?.[sort?.id] || equalFunc)({
          a: get(a, sort?.id),
          b: get(b, sort?.id),
          order: sort?.order,
        }),
      ),
    [idToSortMap, items, sort],
  );

  return {
    createSortHandler,
    items: sortedItems,
    sort,
  };
};

export default useSort;
