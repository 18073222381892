import { APPLICATION_JSON_ACCEPT } from '../constants/requests';
import { API_BASE_URL, buildApiPath } from './common';
import { CREATE_TENANT_ERROR_TEXT, FETCH_TENANT_ERROR_TEXT, TENANTS_PATH } from './constants';

export const fetchTenants = async ({ accessToken }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}`, {
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(FETCH_TENANT_ERROR_TEXT);
  }

  return await response.json();
};

export const postTenant = async ({ accessToken, body }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}`, {
    body: JSON.stringify(body),
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': APPLICATION_JSON_ACCEPT,
    },
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error(CREATE_TENANT_ERROR_TEXT);
  }

  return {
    newTenantId: response.headers.get('location')?.split('/tenants/')[1],
    newTenantName: body.name,
  };
};
