/* eslint-disable no-template-curly-in-string */

export const createRequiredError = (label) => `${label} is required`;
export const TRY_AGAIN_TEXT = 'TRY AGAIN';

export const createMinimumErrorText = (min) => `Minimum is ${min}`;

export const MINIMUM_ERROR_TEXT = 'Minimum is ${min}';
export const MAXIMUM_ERROR_TEXT = 'Maximum is ${max}';
export const INTEGER_ERROR_TEXT = 'Must be an integer';
