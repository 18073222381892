import { Snackbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FETCH_TENANTS_CACHE_KEY, FETCH_TENANT_CACHE_KEY } from '../../shared/api/constants';
import useTenant from '../../shared/api/use-tenant';
import Button from '../../shared/components/Button';
import FetchError from '../../shared/components/Fetch-Error';
import {
  ButtonContainer,
  ContentContainer,
  ErrorContainer,
} from '../../shared/components/Page-With-Side-Navigation';
import { SkeletonIfLoading } from '../../shared/components/Skeleton';
import ChangeTenantForm from '../../shared/components/forms/Change-Tenant-Form';
import FormSubmissionErrorAlert from '../../shared/components/forms/Form-Submission-Error-Alert';
import { FormStack, FormStackContainer } from '../../shared/components/forms/Sections';
import useDependentRequests from '../../shared/hooks/use-dependent-requests';
import useSuccessSnackbar from '../../shared/hooks/use-success-snackbar';
import { editTenant } from '../../tenant/api';
import {
  EDIT_TENANT_DETAILS_ERROR_DISPLAY_TEXT,
  TENANT_DETAILS_LOAD_ERROR_DISPLAY_TEXT,
  TENANT_DETAILS_SAVE_BUTTON_TEXT,
  TENANT_DETAILS_SAVE_SUCCESS_MESSAGE,
} from './constants';

const formId = 'edit-tenant-details';

const EditTenantDetails = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const { handleOpenSuccessSnackbarWithMessage, successSnackbarProps } = useSuccessSnackbar();

  const { tenant: tenantId } = useParams();

  useEffect(() => {
    const message = location?.state?.successMessage;
    if (message) {
      handleOpenSuccessSnackbarWithMessage(message);
      navigate(location.pathname, { replace: true });
    }
  }, []);

  const tenantResult = useTenant({ tenantId });

  const { data: tenant } = tenantResult;

  const { isError, isLoading, refetch } = useDependentRequests([tenantResult]);

  const {
    isError: isEditTenantError,
    isLoading: isEditTenantLoading,
    mutate,
    reset: resetEditTenant,
  } = useMutation(editTenant, {
    onSuccess: () => {
      handleOpenSuccessSnackbarWithMessage(TENANT_DETAILS_SAVE_SUCCESS_MESSAGE);

      queryClient.invalidateQueries(FETCH_TENANTS_CACHE_KEY);
      queryClient.invalidateQueries(FETCH_TENANT_CACHE_KEY);
    },
  });

  if (isError) {
    return (
      <ErrorContainer>
        <FetchError errorMessage={TENANT_DETAILS_LOAD_ERROR_DISPLAY_TEXT} refetch={refetch} />
      </ErrorContainer>
    );
  }

  return (
    <ContentContainer>
      <FormStackContainer>
        <FormStack>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Snackbar {...successSnackbarProps} />
          {isEditTenantError && (
            <FormSubmissionErrorAlert
              errorText={EDIT_TENANT_DETAILS_ERROR_DISPLAY_TEXT}
              formId={formId}
              resetMutate={resetEditTenant}
            />
          )}
          <ChangeTenantForm
            initialNameValueTransformer={(name) => name}
            initialOrgCodeValueTransformer={(orgCode) => orgCode}
            formId={formId}
            isLoading={isLoading}
            mutate={mutate}
            tenant={tenant}
            tenantId={tenantId}
            shouldPrompt
          />
          <ButtonContainer>
            <SkeletonIfLoading isLoading={isLoading}>
              <Button
                form={formId}
                isLoading={isEditTenantLoading}
                type='submit'
                variant='contained'
              >
                {TENANT_DETAILS_SAVE_BUTTON_TEXT}
              </Button>
            </SkeletonIfLoading>
          </ButtonContainer>
        </FormStack>
      </FormStackContainer>
    </ContentContainer>
  );
};

export default EditTenantDetails;
