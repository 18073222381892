import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import BodyPaper from '../shared/components/BodyPaper';
import { NOT_FOUND_TITLE_TEXT } from './constants';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  justify-items: center;
`;

const NotFound = () => {
  return (
    <BodyPaper>
      <Wrapper>
        <Typography variant='h3'>Oops!</Typography>
        <svg
          width='139'
          height='168'
          viewBox='0 0 139 168'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M139 91.5C139 133.474 107.884 167.5 69.5 167.5C31.1162 167.5 0 133.474 0 91.5C0 49.5264 30.5 38 69.5 0C105 34.5 139 49.5264 139 91.5Z'
            fill='#64B6F7'
          />
          <path
            d='M88.5 124C88.5 128.694 75.9706 125 71 125C66.0294 125 54 129.694 54 125C54 120.306 66.0294 111.5 71 111.5C75.9706 111.5 88.5 119.306 88.5 124Z'
            fill='black'
            fillOpacity='0.87'
          />
          <path
            d='M65 124.411C65 139.725 56.2696 144 45.5 144C34.7304 144 26 139.725 26 124.411C26 109.098 34.8636 106.522 45.5 94C55.25 105.741 65 109.098 65 124.411Z'
            fill='#0B79D0'
          />
          <ellipse cx='48.5' cy='75.5' rx='10.5' ry='15.5' fill='black' fillOpacity='0.87' />
          <ellipse cx='88.5' cy='75.5' rx='10.5' ry='15.5' fill='black' fillOpacity='0.87' />
          <path
            d='M57.4997 50.762C57.9676 52.6375 48.7504 51.9142 41.2484 53.7858C33.7463 55.6575 28.2517 63.6585 27.7838 61.783C27.3158 59.9075 32.0519 48.8657 39.5539 46.994C47.0559 45.1224 57.0318 48.8865 57.4997 50.762Z'
            fill='black'
            fillOpacity='0.87'
          />
          <path
            d='M108.103 61.7603C107.272 63.5057 100.615 57.0894 93.6338 53.7667C86.6522 50.444 77.325 53.1294 78.1557 51.384C78.9864 49.6386 89.6603 44.1233 96.642 47.446C103.624 50.7688 108.934 60.0149 108.103 61.7603Z'
            fill='black'
            fillOpacity='0.87'
          />
        </svg>

        <Typography variant='h1'>404</Typography>
        <Typography variant='h5'>{NOT_FOUND_TITLE_TEXT}</Typography>
      </Wrapper>
    </BodyPaper>
  );
};

export default NotFound;
