import { Chip, Grid, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { action, common, tableBorder, text } from '../shared/theme';
import { ellipsisWithOneLine, focusVisible, widthCss } from '../shared/utils/styles';
import { cellHorizontalPadding, labelsSmallWidth, labelsWidth } from './constants';

export const StyledCell = styled.div`
  padding-bottom: 16px;
  padding-left: ${cellHorizontalPadding};
  padding-right: ${cellHorizontalPadding};
  padding-top: 16px;

  ${widthCss}
`;

export const LabelsCell = styled(StyledCell).attrs(() => ({
  $width: labelsWidth,
}))`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    ${({ $isDetailOpen }) => $isDetailOpen && 'display: none;'}
  }

  ${({ theme }) => theme.breakpoints.down('xl')} {
    ${({ $isDetailOpen }) =>
      !$isDetailOpen &&
      `width: ${labelsSmallWidth};
    min-width: ${labelsSmallWidth};
    max-width: ${labelsSmallWidth};`}
  }
`;

export const LabelGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 1,
}))``;

export const LabelChip = styled(Chip).attrs(() => ({
  size: 'small',
  variant: 'outlined',
}))``;

export const IconContainer = styled(Stack).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  height: 40px;
  width: 40px;
`;

export const TableRow = styled(Stack).attrs(() => ({ direction: 'row' }))`
  border: ${tableBorder};
  cursor: pointer;

  background-color: ${common.white};

  ${({ $isSelected }) => $isSelected && `background-color: ${action.selected};`}

  &:hover {
    ${({ $isSelected }) => !$isSelected && `background-color: ${action.hover};`}
  }

  ${focusVisible}
`;

export const TableRowNoInteraction = styled(TableRow)`
  pointer-events: none;
`;

export const TenantNameCell = styled(StyledCell)`
  flex: 1;
  padding-top: 22px;
`;

export const TenantName = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  ${ellipsisWithOneLine}
`;

export const TenantId = styled(Typography).attrs(() => ({
  variant: 'body2',
}))`
  color: ${text.secondary};
  ${ellipsisWithOneLine}
  -webkit-line-clamp: ${({ $numberOfLines }) => $numberOfLines};
`;

export const TenantOrgCodeCell = styled(StyledCell)`
  flex: 1;
  padding-top: 22px;
`;

export const TenantOrgCode = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  ${ellipsisWithOneLine}
`;
