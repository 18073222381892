import React from 'react';

import DetailsEmptyState from '../shared/components/Details-Empty-State';
import { SELECT_TENANT_SUB_TEXT, SELECT_TENANT_TITLE_TEXT } from './constants';

const TenantDetailsNotSelected = () => (
  <DetailsEmptyState
    subText={SELECT_TENANT_SUB_TEXT}
    svgChildren={
      <path
        d='M41.6 52.8L52.8 80.8M92 30.4L108.8 69.6M75.2 113.84V147.44M41.6 141.84L66.8 113.84L86.4 108.24L114.4 141.84M8 69.6L19.2 97.6L148 58.4L125.6 8L8 69.6Z'
        stroke='#E0E0E0'
        strokeWidth='16'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    }
    title={SELECT_TENANT_TITLE_TEXT}
  />
);

export default TenantDetailsNotSelected;
