import PropTypes from 'prop-types';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { FETCH_TENANTS_CACHE_KEY } from '../shared/api/constants';
import TenantModal from './Tenant-Modal';
import { createTenant } from './api';
import {
  CREATE_TENANT_DIALOG_DESCRIPTION,
  CREATE_TENANT_DIALOG_TITLE,
  CREATE_TENANT_ERROR_DISPLAY_TEXT,
  OPEN_CREATED_TENANT_BUTTON_TEXT,
  SUBMIT_CREATE_TENANT_BUTTON_TEXT,
} from './constants';

const formId = 'create-scenario-form';

const CreateTenant = ({ handleClose, isOpen }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    isError,
    isLoading: isCreateLoading,
    mutate,
    reset,
  } = useMutation(createTenant, {
    onSuccess: ({ newTenantId, newTenantName }) => {
      queryClient.invalidateQueries(FETCH_TENANTS_CACHE_KEY);

      navigate(`/${newTenantId}`, {
        successMessage: `Success. ${newTenantName} has been created.`,
      });
    },
  });

  return (
    <TenantModal
      completeDescription={CREATE_TENANT_DIALOG_DESCRIPTION}
      formId={formId}
      handleClose={handleClose}
      isDialogOpen={isOpen}
      isMutateError={isError}
      isMutateLoading={isCreateLoading}
      modalTitle={CREATE_TENANT_DIALOG_TITLE}
      mutate={mutate}
      openTenantSnackbarText={OPEN_CREATED_TENANT_BUTTON_TEXT}
      resetMutate={reset}
      submitButtonText={SUBMIT_CREATE_TENANT_BUTTON_TEXT}
      submitErrorText={CREATE_TENANT_ERROR_DISPLAY_TEXT}
    />
  );
};

CreateTenant.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CreateTenant;
