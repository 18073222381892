import { ErrorOutline } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { grey, text } from '../theme';
import { RETRY_BUTTON_TEXT } from './constants';

const ErrorMessage = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  color: ${text.disabled};
`;

const Container = styled(Stack).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  spacing: 7,
}))`
  margin-top: 128px;
`;

const ErrorIcon = styled(ErrorOutline).attrs(() => ({
  fontSize: 'inherit',
}))`
  color: ${grey[300]};
  font-size: 156px;
`;

const FetchError = ({ errorMessage, refetch }) => (
  <Container>
    <ErrorIcon />
    <Stack alignItems='center' justifyContent='center' spacing={5}>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <Button onClick={refetch} variant='contained'>
        {RETRY_BUTTON_TEXT}
      </Button>
    </Stack>
  </Container>
);

FetchError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default FetchError;
