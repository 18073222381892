import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { text } from '../theme';

const SelectSomethingTitle = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  color: ${text.disabled};
`;

const SelectSomethingSubText = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  color: ${text.disabled};
  text-align: center;
  width: ${({ $width }) => $width}px;
`;

const SomethingNotSelected = styled(Stack).attrs(() => ({
  alignItems: 'center',
  spacing: 5,
}))`
  padding-top: 128px;
  overflow-y: auto;
`;

const SomethingNotSelectedTextContainer = styled(Stack).attrs(() => ({
  alignItems: 'center',
  spacing: 1,
}))``;

const svgSize = 156;
const svgSizePixels = `${svgSize}px`;

const Svg = styled.svg`
  min-height: ${svgSizePixels};
  min-width: ${svgSizePixels};
`;

const DetailsEmptyState = ({ subText, svgChildren, title }) => {
  const { ref: titleRef, width: titleWidth } = useResizeObserver();

  return (
    <SomethingNotSelected>
      <Svg
        width={svgSize}
        height={svgSize}
        viewBox={`0 0 ${svgSize} ${svgSize}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {svgChildren}
      </Svg>
      <SomethingNotSelectedTextContainer>
        <SelectSomethingTitle ref={titleRef}>{title}</SelectSomethingTitle>
        <SelectSomethingSubText $width={titleWidth}>{subText}</SelectSomethingSubText>
      </SomethingNotSelectedTextContainer>
    </SomethingNotSelected>
  );
};

DetailsEmptyState.propTypes = {
  subText: PropTypes.string.isRequired,
  svgChildren: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DetailsEmptyState;
