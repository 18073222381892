import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { FETCH_TENANTS_CACHE_KEY, FETCH_TENANT_CACHE_KEY } from '../shared/api/constants';
import TenantModal from './Tenant-Modal';
import { editTenant } from './api';
import {
  EDIT_TENANT_DIALOG_DESCRIPTION,
  EDIT_TENANT_DIALOG_TITLE,
  EDIT_TENANT_ERROR_DISPLAY_TEXT,
  OPEN_EDITED_TENANT_BUTTON_TEXT,
  SUBMIT_EDIT_BUTTON_TEXT,
} from './constants';

const formId = 'edit-tenant-form';

const EditTenant = ({ handleClose, handleSelectTenant, tenantId }) => {
  const [snackbarInfo, setSnackbarInfo] = useState(null);

  const queryClient = useQueryClient();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const {
    isError,
    isLoading: isDuplicateLoading,
    mutate,
    reset,
  } = useMutation(editTenant, {
    onSuccess: ({ name, editedTenantId }) => {
      handleSelectTenant(editedTenantId);
      setSnackbarInfo({ name, editedTenantId });
      setIsSnackbarOpen(true);
      handleClose();
      queryClient.invalidateQueries(FETCH_TENANTS_CACHE_KEY);
      queryClient.invalidateQueries(FETCH_TENANT_CACHE_KEY);
    },
  });

  return (
    <TenantModal
      descriptionPreText={EDIT_TENANT_DIALOG_DESCRIPTION}
      formId={formId}
      handleClose={handleClose}
      isMutateError={isError}
      isMutateLoading={isDuplicateLoading}
      isSnackbarOpen={isSnackbarOpen}
      modalTitle={EDIT_TENANT_DIALOG_TITLE}
      mutate={mutate}
      openTenantSnackbarText={OPEN_EDITED_TENANT_BUTTON_TEXT}
      resetMutate={reset}
      tenantId={tenantId}
      setIsSnackbarOpen={setIsSnackbarOpen}
      snackbarLink={`/tenants/${snackbarInfo?.editedTenantId}`}
      snackbarMessage={`Success. Details for ${snackbarInfo?.name} have been updated successfully.`}
      submitButtonText={SUBMIT_EDIT_BUTTON_TEXT}
      submitErrorText={EDIT_TENANT_ERROR_DISPLAY_TEXT}
    />
  );
};

EditTenant.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSelectTenant: PropTypes.func.isRequired,
  tenantId: PropTypes.string,
};

EditTenant.defaultProps = {
  tenantId: undefined,
};

export default EditTenant;
