import { CircularProgress, Button as MaterialButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { common } from '../theme';
import { SKELETON_LOADER_TEST_ID } from './constants';

const TextWrapper = styled.div`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
`;

const StyledButton = styled(MaterialButton)`
  position: relative;
`;

const StyledLoader = styled(CircularProgress)`
  height: 1em;
  width: 1em;
  position: absolute;
`;

const WhiteBackground = styled.div`
  background-color: ${common.white};
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

const Button = ({ children, isLoading, shouldShowWhiteBackground, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledButton disabled={isLoading} {...rest}>
    <TextWrapper $isLoading={isLoading}>{children}</TextWrapper>
    {isLoading && (
      <StyledLoader data-testid={SKELETON_LOADER_TEST_ID} color='inherit' size='1.5em' />
    )}
    {shouldShowWhiteBackground && <WhiteBackground />}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  shouldShowWhiteBackground: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
  shouldShowWhiteBackground: false,
};

export default Button;
