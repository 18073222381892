import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { common, grey, text } from '../theme';
import SvgStopScaling from './Svg-Stop-Scaling';
import { EMPTY_TABLE_ARROW_TEST_ID } from './constants';

const barWidth = 7;

const Container = styled(Stack).attrs(() => ({
  alignItems: 'center',
  spacing: 2.5,
}))`
  background-color: ${common.white};
  box-sizing: border-box;
  height: 100%;
  padding-top: ${`${32 - barWidth}px`};
`;

const Title = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  color: ${text.disabled};
`;

const SubText = styled(Typography).attrs(() => ({ variant: 'body1' }))`
  color: ${text.disabled};
`;

const EmptyTable = ({ action, shouldHideArrow, subText, title }) => {
  const { ref: containerRef, width: containerWidth } = useResizeObserver();

  const distanceFromLeft = 36;
  const width = containerWidth - distanceFromLeft * 2 || 200;
  const height = 200;

  const middleWidth = width / 2;
  const halfBarWidth = barWidth / 2;

  const triangleWidth = 40;
  const halfTriangleWidth = triangleWidth / 2;
  const triangleHeight = 34;

  const middlePoint = (height + triangleHeight) / 2;

  const verticalBarHeight = height - middlePoint;

  const leftBarX = halfTriangleWidth;

  const bottomOfTriangle = triangleHeight + barWidth;

  const bottomLeftTriangle = `${halfBarWidth},${bottomOfTriangle}`;
  const bottomRightTriangle = `${triangleWidth + halfBarWidth},${bottomOfTriangle}`;
  const topTriangle = `${halfTriangleWidth + halfBarWidth},${barWidth}`;

  const fill = grey[300];

  return (
    <Container ref={containerRef}>
      <SvgStopScaling
        minWidth={width}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {!shouldHideArrow && (
          <>
            {/* triangle */}
            <polyline
              data-testid={EMPTY_TABLE_ARROW_TEST_ID}
              points={[
                bottomLeftTriangle,
                bottomRightTriangle,
                topTriangle,
                bottomLeftTriangle,
              ].join(' ')}
              fill={fill}
            />
            {/* top bar */}
            <rect
              fill={fill}
              height={verticalBarHeight}
              width={barWidth}
              x={leftBarX}
              y={bottomOfTriangle}
            />
            {/* middle bar */}
            <rect
              fill={fill}
              height={barWidth}
              width={middleWidth - halfTriangleWidth}
              x={leftBarX}
              y={middlePoint}
            />
            {/* bottom bar */}
            <rect
              fill={fill}
              height={verticalBarHeight}
              width={barWidth}
              x={middleWidth - halfBarWidth}
              y={middlePoint}
            />
          </>
        )}
      </SvgStopScaling>
      <SvgStopScaling
        width='140'
        height='140'
        viewBox='0 0 140 140'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        $width={140}
      >
        <path
          d='M126 0H14C10.2926 0.0183991 6.74236 1.4993 4.12083 4.12083C1.4993 6.74236 0.0183991 10.2926 0 14V126C0.0183991 129.707 1.4993 133.258 4.12083 135.879C6.74236 138.501 10.2926 139.982 14 140H90.09C91.9273 140.004 93.7469 139.64 95.4418 138.931C97.1368 138.222 98.6729 137.181 99.96 135.87L135.87 99.96C137.181 98.6729 138.222 97.1368 138.931 95.4418C139.64 93.7469 140.004 91.9273 140 90.09V14C139.982 10.2926 138.501 6.74236 135.879 4.12083C133.258 1.4993 129.707 0.0183991 126 0ZM16.8 16.8H123.2V81.2H89.6C87.3722 81.2 85.2356 82.085 83.6603 83.6603C82.085 85.2356 81.2 87.3722 81.2 89.6V123.2H16.8V16.8ZM98 114.1V98H114.1L98 114.1Z'
          fill={fill}
        />
      </SvgStopScaling>
      <Stack alignItems='center' justifyContent='center' spacing={1}>
        <Title>{title}</Title>
        <Stack alignItems='center' spacing={2.5}>
          <SubText>{subText}</SubText>
          {action}
        </Stack>
      </Stack>
    </Container>
  );
};

EmptyTable.propTypes = {
  action: PropTypes.node,
  shouldHideArrow: PropTypes.bool,
  subText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EmptyTable.defaultProps = {
  action: undefined,
  shouldHideArrow: false,
};

export default EmptyTable;
