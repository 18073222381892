import { deleteTenant as deleteTenantApi, patchTenant } from '../shared/api/tenant';
import { postTenant } from '../shared/api/tenants';

export const editTenant = async ({ accessToken, description, name, tenantId }) => {
  const body = {
    description,
    name,
  };

  await patchTenant({ accessToken, body, tenantId });

  return {
    editedTenantId: tenantId,
    name,
  };
};

export const createTenant = async ({ accessToken, name, orgCode }) => {
  const body = {
    name,
    orgCode,
  };

  const data = await postTenant({ accessToken, body });

  return data;
};

export const deleteTenant = async ({ accessToken, tenantId }) => {
  await deleteTenantApi({ accessToken, tenantId });
};
