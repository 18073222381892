import { createTheme } from '@mui/material/styles';

export const headerHeight = '80px';
export const navigationWidth = '260px';

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#3F51B5',
      dark: '#303F9F',
      light: '#7986CB',
    },
    secondary: {
      main: '#F50057',
      dark: '#C51162',
      light: '#FF4081',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            'box-shadow': 'unset',
          },
          '&.MuiButton-sizeSmall': {
            'line-height': 'unset',
            'padding-top': '5px',
            'padding-bottom': '5px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            'background-color': '#e2e4f3',
          },
          '&.MuiTableRow-hover:hover': {
            'background-color': '#e8e8e8',
          },
          '&.Mui-selected:hover': {
            'background-color': '#d4d7eb',
          },
        },
      },
    },
  },
});

const {
  palette: { action, common, error, grey, primary, secondary, success, info, text, warning },
  transitions,
  typography,
} = materialTheme;

export const stackedBarColor = info.main;
export const stackedBarUnselectedColor = `${stackedBarColor}4D`;
export const gantBarSelectedStroke = `#00000099`;
export const disabledGanttColor = grey[300];
export const disabledUnselectedGanttColor = `${disabledGanttColor}4D`;
export const cipHoverColor = info.dark;

export const maxEndFillColor = `${stackedBarColor}14`;
export const maxEndStrokeColor = `${stackedBarColor}80`;
export const maxEndDisabledFillColor = `${disabledGanttColor}14`;
export const maxEndDisabledStrokeColor = `${disabledGanttColor}80`;

export const greyChartLightestColor = '#EDEBEB';
export const greyChartDarkestColor = '#535252';

export const maxContainerWidth = '1920px';
export const minContainerWidth = '768px';

export const appBarHeight = '56px';

export const appBarPaddingNumber = 16;
export const appBarPadding = `${appBarPaddingNumber}px`;
export const bodyPaperMarginTop = '80px';

export const dividerColor = 'rgba(0,0,0,.12)';

export const paperBorder = `1px solid ${dividerColor}`;
export const tableBorder = `1px solid ${dividerColor}`;
export const outlinedBorder = '1px solid rgba(0,0,0,.23)';

export const actionSelectedSolid = '#D8D8D8';
export const actionHoverSolid = '#E9E9E9';
export const actionFocusSolid = '#BCBCBC';

export const iconColors = {
  green: success.dark,
  darkBlue: primary.main,
  darkRed: '#AB2F26',
  lightBlue: '#1769AA',
  lightRed: secondary.dark,
  orange: warning.main,
  yellow: warning.light,
};

export const driverColors = [
  primary.main,
  info.dark,
  secondary.light,
  secondary.dark,
  primary.light,
];

export const backgroundColor = '#fafafa';

export const snackbarAutoHideDuration = 7000;

export const tooltipDelayProps = {
  enterDelay: 500,
  enterNextDelay: 500,
};

export const tooltipInstantProps = {
  enterDelay: 0,
  enterNextDelay: 0,
};

export {
  action,
  common,
  error,
  grey,
  primary,
  secondary,
  success,
  info,
  text,
  transitions,
  typography,
};
