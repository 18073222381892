export const CHANGE_TENANT_CONFIRM_LEAVE_TEXT =
  'Leave page and discard changes? This action cannot be undone.';

export const TENANT_NAME_LABEL_TEXT = 'Tenant Name';
export const TENANT_ORG_CODE_LABEL_TEXT = 'Tenant Org Code';

export const FORM_SUBMISSION_ERROR_CLOSE_ICON_TEST_ID = 'form-submission-error-close-icon';

export const reactHookFormSetValueProps = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};
