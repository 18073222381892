import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BodyHeader,
  FlexOneContainer,
  HeaderContainer,
  Title,
} from '../shared/components/BodyHeader';
import { Content, DetailsButton, LeftContainer } from '../shared/components/Page-With-Side-Sheet';
import { TENANTS_PATH } from '../shared/constants/routes';
import CreateTenant from './Create-Tenant';
import EditTenant from './Edit-Tenant';
import TenantDetails from './Tenant-Details';
import TenantTable from './Tenant-Table';
import { ACTIVE_TENANTS_LABEL_TEXT, CREATE_TENANT_BUTTON_TEXT } from './constants';

const Tenants = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [editTenantId, setEditTenantId] = useState(null);
  const [isCreateTenantOpen, setIsCreateTenantOpen] = useState(false);
  const [shouldShowDetails, setShouldShowDetails] = useState(true);

  const { tenant: selectedTenantId } = useParams();

  const navigate = useNavigate();

  const handleDetailsToggle = () => {
    setShouldShowDetails(!shouldShowDetails);

    if (shouldShowDetails) {
      navigate('/', { replace: true });
    }
  };

  const handleSetSelectedTenantId = (id) => {
    setShouldShowDetails(true);
    navigate(`${TENANTS_PATH}/${id}`, { replace: true });
  };

  const handleEditTenant = (id) => {
    setEditTenantId(id);
  };

  const handleCloseEditTenant = () => setEditTenantId(null);

  const handleCloseCreateTenant = () => setIsCreateTenantOpen(false);

  const handleOpenCreateTenant = () => setIsCreateTenantOpen(true);

  const isOpen = !isEmpty && shouldShowDetails;

  return (
    <>
      <EditTenant
        handleClose={handleCloseEditTenant}
        handleSelectTenant={handleSetSelectedTenantId}
        tenantId={editTenantId}
      />
      <CreateTenant
        handleClose={handleCloseCreateTenant}
        handleSelectTenant={handleSetSelectedTenantId}
        isOpen={isCreateTenantOpen}
      />
      <BodyHeader>
        <HeaderContainer>
          <FlexOneContainer alignItems='flex-start'>
            <Button
              onClick={handleOpenCreateTenant}
              size='small'
              startIcon={<Add />}
              variant='contained'
            >
              {CREATE_TENANT_BUTTON_TEXT}
            </Button>
          </FlexOneContainer>
          <Title>{ACTIVE_TENANTS_LABEL_TEXT}</Title>
          <FlexOneContainer>
            {!isEmpty && <DetailsButton isOpen={isOpen} onClick={handleDetailsToggle} />}
          </FlexOneContainer>
        </HeaderContainer>
      </BodyHeader>
      <Content>
        <LeftContainer>
          <TenantTable
            selectedTenantId={selectedTenantId}
            setIsEmpty={setIsEmpty}
            setSelectedTenantId={handleSetSelectedTenantId}
          />
        </LeftContainer>
        <TenantDetails
          handleEditTenant={handleEditTenant}
          open={isOpen}
          selectedTenantId={selectedTenantId}
        />
      </Content>
    </>
  );
};

export default Tenants;
