import { REACT_APP_API_BASE_URL, REACT_APP_API_ROOT } from '../environment';

export const buildApiPath = (rootUrl, path) => {
  let r = rootUrl.endsWith('/') ? rootUrl.slice(0, -1) : rootUrl;
  if (path === '/') {
    return r;
  }
  if (path) {
    let p = path.startsWith('/') ? path.slice(1) : path;
    if (p) {
      p = path.endsWith('/') ? p.slice(0, -1) : p;
    }
    return `${r}/${p}`;
  } else {
    return r;
  }
};

export const API_BASE_URL = buildApiPath(REACT_APP_API_BASE_URL, REACT_APP_API_ROOT);
