export const APP_BAR_TEST_ID = 'layout-app-bar';

export const SWITCHER_GLOBAL_WORKSPACE_TITLE = 'Global Workspace';
export const SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION = 'Manage Tenants';

export const SWITCHER_ALL_TENANTS_TITLE = 'All Tenants';
export const SWITCHER_ALL_TENANTS_DESCRIPTION = 'Select or create a tenant to explore';

export const SWITCHER_LAST_TENANT_ID_STORAGE_KEY = 'switcher-last-tenant-id';

export const SWITCHER_BUTTON_TEST_ID = 'tenant-switcher-button';

export const SWITCHER_TENANT_CURRENTLY_EDITING = "You're editing this tenant right now";
export const SWITCHER_TENANT_NOT_EDITING = 'Return to edit your last active tenant';

export const SWITCHER_OPTION_TEST_ID = 'tenant-switcher-option';
