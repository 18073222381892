import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export default () => {
  const [accessToken, setAccessToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();

      setAccessToken(token);
    };

    getAccessToken();
  }, []);

  return accessToken;
};
