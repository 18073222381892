import { withAuthenticationRequired } from '@auth0/auth0-react';
import * as React from 'react';
import { Routes as ReactRouterRoutes, Route, useMatch } from 'react-router-dom';

import Layout from './layout/Layout';
import NotFound from './not-found/Not-Found';
import { SETUP_PATH, addTenantRouteParam } from './shared/constants/routes';
import TenantSetup from './tenant-setup/Tenant-Setup';
import EditTenantDetails from './tenant-setup/tenant/Edit-Tenant-Details';
import Tenants from './tenant/Tenants';

const tenantSelectedRoutes = {
  setup: `${addTenantRouteParam(SETUP_PATH)}/*`,
};

const Routes = () => {
  const setupMatch = useMatch(tenantSelectedRoutes.setup);
  const tenantId = setupMatch?.params?.tenant;
  return (
    <Layout tenantId={tenantId}>
      <ReactRouterRoutes>
        <Route path='/' element={<Tenants />} />
        <Route path='/tenants' element={<Tenants />} />
        <Route path='/tenants/:tenant' element={<Tenants />} />
        <Route path='/tenants/:tenant/tenant-setup' element={<TenantSetup />}>
          <Route index path='tenant-details' element={<EditTenantDetails />} />
          <Route path='tenant-drivers' element={<EditTenantDetails />} />
          <Route path='tenant-utilities' element={<EditTenantDetails />} />
          <Route path='tenant-labels' element={<EditTenantDetails />} />
          <Route path='tenant-settings' element={<EditTenantDetails />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </ReactRouterRoutes>
    </Layout>
  );
};

export default withAuthenticationRequired(Routes);
