import {
  ArrowDownward, // KeyboardArrowDown
} from '@mui/icons-material';
import {
  Collapse, // IconButton,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useTenants from '../shared/api/use-tenants';
import EmptyTable from '../shared/components/Empty-Table';
import FetchError from '../shared/components/Fetch-Error';
import useSort from '../shared/components/table/use-sort';
import { ASCENDING_KEY } from '../shared/constants/sorting';
import { grey, text } from '../shared/theme';
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ENTER,
  SPACE,
} from '../shared/utils/keyboard-event-codes';
import { stringCompare } from '../shared/utils/sorting';
import { focusVisible, noTextHighlight, rotate, widthCss } from '../shared/utils/styles';
import TenantTableRow from './Tenant-Table-Row';
import TenantTableRowLoading from './Tenant-Table-Row-Loading';
import {
  FETCH_TENANTS_ERROR_DISPLAY_TEXT,
  TENANT_NAME_LABEL_HEADER,
  TENANT_ORG_CODE_LABEL_HEADER,
  TENANT_SORT_ARROW_TEST_ID,
  TENANT_TABLE_EMPTY_SUBTEXT,
  TENANT_TABLE_EMPTY_TITLE,
  cellHorizontalPadding,
} from './constants';

const SortableHeaderContainer = styled(Stack).attrs(() => ({
  alignItems: 'flex-end',
  direction: 'row',
  justifyContent: 'space-between',
}))`
  height: 100%;
  text-align: left;
  padding-bottom: 4px;

  ${({ $flex }) => $flex && `flex: ${$flex};`}

  ${widthCss}

  ${({ $shouldHideBelowMedium, theme }) =>
    $shouldHideBelowMedium &&
    `${theme.breakpoints.down('md')} {
      display: none;
    }`}
`;

const TableHeader = styled(Stack).attrs(() => ({
  alignItems: 'flex-end',
  direction: 'row',
}))`
  box-sizing: border-box;
  background-color: ${grey[50]};
  height: 56px;
  position: sticky;
  top: 0;
  z-index: 1;
  ${noTextHighlight}
`;

const ThTypography = styled(Typography).attrs(() => ({ variant: 'body1' }))`
  color: ${text.secondary};
  font-size: 14px;
  font-weight: 500;
  padding-left: ${cellHorizontalPadding};
`;

const SortArrow = styled(ArrowDownward).attrs(() => ({ fontSize: 'small' }))`
  opacity: 0;
  color: ${text.secondary};

  ${rotate}
`;

const LabelAndArrow = styled(Stack).attrs(() => ({
  alignItems: 'flex-end',
  direction: 'row',
  spacing: 0.5,
}))`
  ${({ $isCollapsed }) => !$isCollapsed && 'cursor: pointer;'}
  margin-bottom: 8px;

  ${SortArrow} {
    ${({ $isActive, $isCollapsed }) => $isActive && !$isCollapsed && `opacity: 1;`}
  }

  &:hover {
    ${ThTypography}, ${SortArrow} {
      ${({ $isCollapsed }) => !$isCollapsed && 'opacity: 1;'}
      ${({ $isActive, $isCollapsed }) => !$isCollapsed && !$isActive && `color: ${text.disabled};`}
    }
  }

  ${focusVisible}
`;

const SortableHeader = ({
  children,
  flex,
  handleSort,
  isActive,
  isCollapsed,
  label,
  shouldHideBelowMedium,
  sortDirection,
  width,
}) => {
  const handleKeyDown = (event) => {
    if ([ENTER, SPACE].includes(event.code)) {
      handleSort();
    }
  };

  const mouseProps = {
    onClick: handleSort,
    onKeyDown: handleKeyDown,
    tabIndex: 0,
  };

  return (
    <SortableHeaderContainer
      $flex={flex}
      $shouldHideBelowMedium={shouldHideBelowMedium}
      $width={width}
    >
      <LabelAndArrow
        $isActive={isActive}
        $isCollapsed={isCollapsed}
        tabIndex={-1}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isCollapsed ? {} : mouseProps)}
      >
        <ThTypography>{label}</ThTypography>
        <SortArrow
          $rotate={isActive && sortDirection === 'desc'}
          data-testid={TENANT_SORT_ARROW_TEST_ID}
        />
      </LabelAndArrow>
      {children}
    </SortableHeaderContainer>
  );
};

SortableHeader.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.number,
  handleSort: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  shouldHideBelowMedium: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  width: PropTypes.string,
};

SortableHeader.defaultProps = {
  children: undefined,
  flex: undefined,
  shouldHideBelowMedium: undefined,
  width: undefined,
};

const TableBody = styled(Stack).attrs(() => ({ spacing: 2 }))`
  position: relative;
`;

const TableContainer = styled.div`
  margin: 16px;
`;

const numberOfColumns = 2;

const TenantTable = ({ selectedTenantId, setIsEmpty, setSelectedTenantId }) => {
  const { data: unsortedTenants, isError, isLoading, refetch } = useTenants();

  const {
    createSortHandler,
    items: tenants,
    sort,
  } = useSort({
    defaultSort: { id: 'name', order: ASCENDING_KEY },
    idToSortMap: {
      name: stringCompare,
      orgCode: stringCompare,
    },
    items: unsortedTenants,
  });

  const isTenantsEmpty = !isLoading && !isError && !tenants?.length;

  useEffect(() => {
    setIsEmpty(isTenantsEmpty);
  }, [isTenantsEmpty]);

  const [
    isCollapsed,
    // setIsCollapsed
  ] = useState(false);
  const [columnFocusIndex, setColumnFocusIndex] = useState(0);
  const [rowFocusIndex, setRowFocusIndex] = useState(null);

  const createTableKeyDownHandler = (id) => (event) => {
    switch (event.code) {
      case ENTER:
      case SPACE:
        event.preventDefault();
        setSelectedTenantId(id);
        break;
      case ARROW_DOWN:
        if (rowFocusIndex < tenants?.length - 1) {
          setRowFocusIndex((rowFocusIndex || 0) + 1);
        }

        break;
      case ARROW_UP:
        if (rowFocusIndex > 0) {
          setRowFocusIndex(rowFocusIndex - 1);
        }
        break;
      case ARROW_RIGHT:
        if (columnFocusIndex < numberOfColumns - 1) {
          setColumnFocusIndex(columnFocusIndex + 1);
        } else {
          setColumnFocusIndex(0);
        }
        break;
      case ARROW_LEFT:
        if (columnFocusIndex > 0) {
          setColumnFocusIndex(columnFocusIndex - 1);
        } else {
          setColumnFocusIndex(numberOfColumns - 1);
        }
        break;
      default:
        break;
    }
  };

  const createFocusHandler = (index) => () => {
    setRowFocusIndex(index);
  };

  const RowSkeleton = () => <TenantTableRowLoading />;

  if (isTenantsEmpty) {
    return <EmptyTable subText={TENANT_TABLE_EMPTY_SUBTEXT} title={TENANT_TABLE_EMPTY_TITLE} />;
  }

  if (isError) {
    return <FetchError errorMessage={FETCH_TENANTS_ERROR_DISPLAY_TEXT} refetch={refetch} />;
  }

  return (
    <TableContainer>
      <TableHeader>
        <SortableHeader
          flex={1}
          label={TENANT_NAME_LABEL_HEADER}
          isActive={sort?.id === 'name'}
          isCollapsed={isCollapsed}
          handleSort={createSortHandler('name')}
          sortDirection={sort?.order}
        />
        <SortableHeader
          flex={1}
          label={TENANT_ORG_CODE_LABEL_HEADER}
          isActive={sort?.id === 'orgCode'}
          isCollapsed={isCollapsed}
          handleSort={createSortHandler('orgCode')}
          sortDirection={sort?.order}
        />
      </TableHeader>
      <Collapse in={!isCollapsed} orientation='vertical'>
        <TableBody
          onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              setRowFocusIndex(null);
              setColumnFocusIndex(0);
            }
          }}
        >
          {isLoading && (
            <>
              <RowSkeleton />
              <RowSkeleton />
              <RowSkeleton />
            </>
          )}
          {tenants?.map((tenant, index) =>
            isLoading ? (
              <RowSkeleton key={tenant?.id} />
            ) : (
              <TenantTableRow
                columnFocusIndex={columnFocusIndex}
                handleTableKeyDown={createTableKeyDownHandler(tenant?.id)}
                handleFocus={createFocusHandler(index)}
                isFocused={index === rowFocusIndex}
                isTabbable={index === 0}
                key={tenant?.id}
                tenant={tenant}
                selectedTenantId={selectedTenantId}
                setSelectedTenantId={setSelectedTenantId}
              />
            ),
          )}
        </TableBody>
      </Collapse>
    </TableContainer>
  );
};

TenantTable.propTypes = {
  selectedTenantId: PropTypes.string,
  setIsEmpty: PropTypes.func.isRequired,
  setSelectedTenantId: PropTypes.func.isRequired,
};

TenantTable.defaultProps = {
  selectedTenantId: null,
};

export default TenantTable;
