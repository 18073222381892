import React from 'react';

import { Skeleton } from '../shared/components/Skeleton';
import {
  TableRowNoInteraction,
  TenantName,
  TenantNameCell,
  TenantOrgCode,
  TenantOrgCodeCell,
} from './Tenant-Table-Shared-Components';

const TenantTableRowLoading = () => (
  <TableRowNoInteraction>
    <TenantNameCell>
      <TenantName>
        <Skeleton />
      </TenantName>
    </TenantNameCell>
    <TenantOrgCodeCell>
      <TenantOrgCode>
        <Skeleton />
      </TenantOrgCode>
    </TenantOrgCodeCell>
  </TableRowNoInteraction>
);

export default TenantTableRowLoading;
