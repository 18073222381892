import { Auth0Provider } from '@auth0/auth0-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import ErrorBoundary from './Error-Boundary';
import Routes from './Routes';
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
} from './shared/environment';
import { AppStateProvider } from './shared/hooks/app-state';
import { materialTheme } from './shared/theme';
import createQueryClient from './shared/utils/create-query-client';

const Wrapper = styled.div`
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto, sans-serif;
    margin: 0;
  }
`;

const queryClient = createQueryClient();

export default function App() {
  return (
    <ThemeProvider theme={materialTheme}>
      <ErrorBoundary>
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: REACT_APP_AUTH0_AUDIENCE,
          }}
        >
          <StyledEngineProvider injectFirst>
            <QueryClientProvider client={queryClient}>
              <AppStateProvider>
                <GlobalStyle />
                <BrowserRouter>
                  <Wrapper>
                    <Routes />
                  </Wrapper>
                </BrowserRouter>
              </AppStateProvider>
            </QueryClientProvider>
          </StyledEngineProvider>
        </Auth0Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
