import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const appStateContext = createContext('appState');

const { Provider } = appStateContext;

export const useAppState = () => useContext(appStateContext);

export const AppStateProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    isLoggedIn: false,
  });

  const updateState = (updates) =>
    setAppState({
      ...appState,
      ...updates,
    });

  return <Provider value={{ appState, updateState }}>{children}</Provider>;
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
