import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TableRow,
  TenantId,
  TenantName,
  TenantNameCell,
  TenantOrgCode,
  TenantOrgCodeCell,
} from './Tenant-Table-Shared-Components';
import { TENANT_TABLE_ROW_TEST_ID } from './constants';

const TenantTableRow = ({
  columnFocusIndex,
  handleFocus,
  handleTableKeyDown,
  isFocused,
  isTabbable,
  tenant,
  selectedTenantId,
  setSelectedTenantId,
}) => {
  const { id, name, orgCode } = tenant;

  const rowRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isFocused) {
      [
        rowRef,
        // starRef
      ][columnFocusIndex].current.focus();
    }
  }, [isFocused, columnFocusIndex]);

  useEffect(() => {
    if (selectedTenantId === id) {
      rowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  const selectTenant = (event) => {
    if (event) {
      event.preventDefault();
    }

    setSelectedTenantId(id);
  };

  return (
    <TableRow
      data-testid={`${TENANT_TABLE_ROW_TEST_ID}-${id}`}
      key={id}
      onClick={selectTenant}
      onDoubleClick={() => navigate(`/tenants/${id}/tenant-setup/tenant-details`)}
      onFocus={handleFocus}
      onKeyDown={handleTableKeyDown}
      $isSelected={id === selectedTenantId}
      ref={rowRef}
      tabIndex={isTabbable ? 0 : -1}
    >
      <TenantNameCell>
        <TenantName>{name}</TenantName>
        <TenantId $numberOfLines={1}>{id}</TenantId>
      </TenantNameCell>
      <TenantOrgCodeCell>
        <TenantOrgCode>{orgCode}</TenantOrgCode>
      </TenantOrgCodeCell>
    </TableRow>
  );
};

TenantTableRow.propTypes = {
  columnFocusIndex: PropTypes.number.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleTableKeyDown: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isTabbable: PropTypes.bool.isRequired,
  tenant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    orgCode: PropTypes.string,
  }).isRequired,
  selectedTenantId: PropTypes.string,
  setSelectedTenantId: PropTypes.func.isRequired,
};

TenantTableRow.defaultProps = {
  selectedTenantId: null,
};

export default TenantTableRow;
