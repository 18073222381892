export const ACTIVE_TENANTS_LABEL_TEXT = 'Active Tenants';
export const CREATE_TENANT_BUTTON_TEXT = 'Create Tenant';

export const EDIT_TENANT_DIALOG_DESCRIPTION = 'Edit details of';
export const EDIT_TENANT_DIALOG_TITLE = 'Edit tenant details';
export const EDIT_TENANT_ERROR_DISPLAY_TEXT = 'Failed to save changes';
export const OPEN_EDITED_TENANT_BUTTON_TEXT = 'OPEN TENANT';
export const SUBMIT_EDIT_BUTTON_TEXT = 'SAVE CHANGES';

export const CANCEL_MODAL_BUTTON_TEXT = 'CANCEL';
export const CLOSE_TENANT_DIALOG_SNACKBAR_TEST_ID = 'tenant-dialog-snackbar-close';
export const CLOSE_TENANT_DIALOG_TEST_ID = 'tenant-dialog-close';

export const CREATE_TENANT_ERROR_TEXT = 'Failed to create tenant';
export const DELETE_TENANT_ERROR_TEXT = 'Failed to delete tenant';
export const FETCH_TENANTS_ERROR_TEXT = 'Failed to fetch tenants';

export const CREATE_TENANT_DIALOG_DESCRIPTION = 'Create a new tenant';
export const CREATE_TENANT_DIALOG_TITLE = 'Create tenant';
export const CREATE_TENANT_ERROR_DISPLAY_TEXT = 'Failed to create tenant';
export const OPEN_CREATED_TENANT_BUTTON_TEXT = 'Open Tenant';
export const SUBMIT_CREATE_TENANT_BUTTON_TEXT = 'CREATE TENANT';

export const cellHorizontalPadding = '8px';
export const tableTitleWidth = '72px';
export const labelsWidth = '320px';
export const labelsSmallWidth = '280px';
export const latestEditWidth = '120px';

export const TENANT_TABLE_ROW_TEST_ID = 'tenant-table-row';

export const FETCH_TENANTS_ERROR_DISPLAY_TEXT = 'Failed to Load Tenant List';
export const TENANT_NAME_LABEL_HEADER = 'Tenant Name';
export const TENANT_ORG_CODE_LABEL_HEADER = 'Org Code';
export const TENANT_SORT_ARROW_TEST_ID = 'tenant-sort-arrow';
export const TENANT_TABLE_EMPTY_SUBTEXT = `Use the "+  ${CREATE_TENANT_BUTTON_TEXT}" button to make your first tenant`;
export const TENANT_TABLE_EMPTY_TITLE = 'No Tenants Yet';

export const DELETE_TENANT_CANCEL_BUTTON_TEXT = 'CANCEL';
export const DELETE_TENANT_CONFIRM_BUTTON_TEXT = 'DELETE';
export const DELETE_TENANT_DIALOG_DESCRIPTION =
  'Permanantly delete this tenant? This action cannot be undone.';

export const DELETE_TENANT_BUTTON_TEXT = 'Delete Forever';
export const EDIT_TENANT_BUTTON_TEXT = 'Edit Details';
export const FETCH_TENANT_ERROR_DISPLAY_TEXT = 'Failed to Load Tenant List';
export const MORE_ACTIONS_BUTTON_TEXT = 'MORE';
export const OPEN_TENANT_BUTTON_TEXT = 'OPEN';

export const TENANT_DETAILS_CONTAINER_TEST_ID = 'tenant-details';

export const SELECT_TENANT_SUB_TEXT =
  'When you select a tenant from the left, details will appear here.';
export const SELECT_TENANT_TITLE_TEXT = 'Select Tenant to View Details';
