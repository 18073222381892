import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { appBarPadding, backgroundColor, bodyPaperMarginTop, text } from '../theme';
import { ellipsis } from '../utils/styles';

export const BodyHeader = styled(Stack).attrs(() => ({
  justifyContent: 'flex-end',
}))`
  background-color: ${backgroundColor};
  height: ${bodyPaperMarginTop};
  max-height: ${bodyPaperMarginTop};
`;

export const HeaderContainer = styled(Stack).attrs(() => ({
  alignItems: 'flex-end',
  direction: 'row',
}))`
  padding: ${appBarPadding};
  padding-top: 0;
`;

export const FlexOneContainer = styled(Stack).attrs(({ alignItems, justifyContent }) => ({
  alignItems: alignItems || 'flex-end',
  justifyContent: justifyContent || 'center',
}))`
  flex: 1;
  position: relative;
`;

export const Title = styled(Typography).attrs(() => ({ variant: 'h5' }))`
  color: ${text.secondary};
  ${ellipsis}
`;
