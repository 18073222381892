export const WAVES = 'waves';
export const PLANT = 'plant';
export const LIGHT_BULB = 'lightBulb';
export const BOLT = 'bolt';
export const LOCAL_DRINK = 'localDrink';
export const ENGINEERING = 'engineering';
export const MONETIZATION_ON = 'monetizationOn';
export const LANDSCAPE = 'landscape';
export const WATER_DAMAGE = 'waterDamage';
export const PUBLIC = 'public';
export const ALL_INCLUSIVE = 'allInclusive';
export const MAP = 'map';
export const ENERGY_SAVING = 'energySaving';
export const VOLUNTEER_ACTIVISM = 'volunteerActivism';
export const SCIENCE = 'science';
export const THUNDERSTORM = 'thunderstorm';
export const SAVINGS = 'savings';
export const SHOW_CHART = 'showChart';
export const GAVEL = 'gavel';
export const TIMER = 'timer';
export const LOOKS = 'looks';
export const FAMILY_RESTROOM = 'familyRestroom';
