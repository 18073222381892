const useDependentRequests = (requestResults = []) => {
  const calculateDependentProperty = (property) =>
    !!requestResults?.reduce((acc, current) => acc || current[property], false);

  const isError = calculateDependentProperty('isError');
  const isFetching = calculateDependentProperty('isFetching');
  const isLoading = calculateDependentProperty('isLoading');

  const refetch = () => {
    requestResults.forEach(({ isError: isErrorCurrent, refetch: refetchCurrent }) => {
      if (isErrorCurrent) {
        refetchCurrent();
      }
    });
  };

  return {
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useDependentRequests;
