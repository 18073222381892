import { Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { createRequiredError } from '../../constants/validation';
import useAccessToken from '../../hooks/use-access-token';
import { SkeletonIfLoading, TextSkeletonIfLoading } from '../Skeleton';
import { TENANT_NAME_LABEL_TEXT, TENANT_ORG_CODE_LABEL_TEXT } from './constants';

const FormStack = styled(Stack).attrs(() => ({
  spacing: 4,
}))`
  max-width: 720px;
`;

const InheritWidthSkeletonIfLoading = styled(SkeletonIfLoading)`
  max-width: inherit;
`;

const ChangeTenantForm = ({
  formDescription,
  initialNameValueTransformer,
  initialOrgCodeValueTransformer,
  formId,
  isLoading,
  mutate,
  tenant,
  tenantId,
}) => {
  const accessToken = useAccessToken();

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      orgCode: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    if (tenant) {
      const { name, orgCode } = tenant;

      reset({
        ...getValues(),
        name: name ? initialNameValueTransformer(name) : '',
        orgCode: orgCode ? initialOrgCodeValueTransformer(orgCode) : '',
      });
    }
  }, [tenant]);

  const onSubmit = ({ name, orgCode }) => {
    mutate({
      accessToken,
      orgCode,
      name,
      oldTenantName: tenant?.name,
      tenantId,
    });
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormStack>
        {formDescription && (
          <Typography variant='body1'>
            <TextSkeletonIfLoading isLoading={isLoading}>{formDescription}</TextSkeletonIfLoading>
          </Typography>
        )}
        <InheritWidthSkeletonIfLoading isLoading={isLoading}>
          <Controller
            control={control}
            name='name'
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                inputProps={{ maxLength: 150 }}
                multiline
                label={TENANT_NAME_LABEL_TEXT}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: createRequiredError(TENANT_NAME_LABEL_TEXT) }}
          />
        </InheritWidthSkeletonIfLoading>
        <InheritWidthSkeletonIfLoading isLoading={isLoading}>
          <Controller
            control={control}
            name='orgCode'
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                inputProps={{ maxLength: 150 }}
                multiline
                label={TENANT_ORG_CODE_LABEL_TEXT}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: createRequiredError(TENANT_ORG_CODE_LABEL_TEXT) }}
          />
        </InheritWidthSkeletonIfLoading>
      </FormStack>
    </form>
  );
};

ChangeTenantForm.propTypes = {
  formDescription: PropTypes.string,
  initialNameValueTransformer: PropTypes.func.isRequired,
  initialOrgCodeValueTransformer: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    orgCode: PropTypes.string,
  }),
  tenantId: PropTypes.string,
  shouldPrompt: PropTypes.bool,
};

ChangeTenantForm.defaultProps = {
  formDescription: undefined,
  tenant: undefined,
  tenantId: undefined,
  shouldPrompt: false,
};
export default ChangeTenantForm;
