import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Snackbar, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useTenants from '../shared/api/use-tenants';
import Button from '../shared/components/Button';
import DialogTitle from '../shared/components/Dialog-Title';
import { SkeletonIfLoading } from '../shared/components/Skeleton';
import ChangeTenantForm from '../shared/components/forms/Change-Tenant-Form';
import FormSubmissionErrorAlert from '../shared/components/forms/Form-Submission-Error-Alert';
import useDependentRequests from '../shared/hooks/use-dependent-requests';
import { common, paperBorder, snackbarAutoHideDuration } from '../shared/theme';
import {
  CANCEL_MODAL_BUTTON_TEXT,
  CLOSE_TENANT_DIALOG_SNACKBAR_TEST_ID,
  CLOSE_TENANT_DIALOG_TEST_ID,
} from './constants';

const ButtonContainer = styled(Stack).attrs(() => ({
  direction: 'row-reverse',
  spacing: 2,
}))`
  background-color: ${common.white};
  border-top: ${paperBorder};
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  position: sticky;
  bottom: 0;
`;

const StyledDialogContent = styled(DialogContent)`
  padding-bottom: 24px;
  overflow-x: hidden;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: unset;
  }
`;

const TenantModal = ({
  completeDescription,
  descriptionPreText,
  formId,
  handleClose,
  initialNameValueTransformer,
  initialOrgCodeValueTransformer,
  isDialogOpen,
  isMutateError,
  isMutateLoading,
  isSnackbarOpen,
  modalTitle,
  mutate,
  openTenantSnackbarText,
  resetMutate,
  tenantId,
  setIsSnackbarOpen,
  snackbarMessage,
  snackbarLink,
  submitButtonText,
  submitErrorText,
}) => {
  const [latestTenantId, setLatestTenantId] = useState(null);

  useEffect(() => {
    if (tenantId) {
      setLatestTenantId(tenantId);
    }
  }, [tenantId]);

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const tenantsResult = useTenants();

  const { tenantsById } = tenantsResult;

  const tenant = tenantsById?.[latestTenantId];

  const { isLoading } = useDependentRequests([tenantsResult]);

  return (
    <>
      <Snackbar
        autoHideDuration={snackbarAutoHideDuration}
        open={isSnackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <>
            <Button component={Link} color='secondary' size='small' to={snackbarLink}>
              {openTenantSnackbarText}
            </Button>
            <IconButton
              color='inherit'
              data-testid={CLOSE_TENANT_DIALOG_SNACKBAR_TEST_ID}
              onClick={handleCloseSnackbar}
              size='small'
            >
              <Close fontSize='small' />
            </IconButton>
          </>
        }
      />
      <StyledDialog open={!!(tenantId || isDialogOpen)}>
        <DialogTitle
          closeTestId={CLOSE_TENANT_DIALOG_TEST_ID}
          handleClose={handleClose}
          title={modalTitle}
        />
        {isMutateError && (
          <FormSubmissionErrorAlert
            errorText={submitErrorText}
            formId={formId}
            resetMutate={resetMutate}
          />
        )}
        <StyledDialogContent>
          <ChangeTenantForm
            formDescription={completeDescription || `${descriptionPreText} ${tenant?.name}`}
            initialNameValueTransformer={initialNameValueTransformer}
            initialOrgCodeValueTransformer={initialOrgCodeValueTransformer}
            formId={formId}
            isLoading={isLoading}
            mutate={mutate}
            tenant={tenant}
            tenantId={tenantId}
          />
        </StyledDialogContent>
        <ButtonContainer>
          <SkeletonIfLoading isLoading={isLoading}>
            <Button form={formId} isLoading={isMutateLoading} type='submit' variant='contained'>
              {submitButtonText}
            </Button>
          </SkeletonIfLoading>
          <SkeletonIfLoading isLoading={isLoading}>
            <Button type='button' onClick={handleClose}>
              {CANCEL_MODAL_BUTTON_TEXT}
            </Button>
          </SkeletonIfLoading>
        </ButtonContainer>
      </StyledDialog>
    </>
  );
};

TenantModal.propTypes = {
  completeDescription: PropTypes.string,
  descriptionPreText: PropTypes.string,
  formId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool,
  isMutateError: PropTypes.bool.isRequired,
  isMutateLoading: PropTypes.bool.isRequired,
  isSnackbarOpen: PropTypes.bool,
  initialNameValueTransformer: PropTypes.func,
  initialOrgCodeValueTransformer: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
  openTenantSnackbarText: PropTypes.string.isRequired,
  resetMutate: PropTypes.func.isRequired,
  tenantId: PropTypes.string,
  setIsSnackbarOpen: PropTypes.func,
  snackbarLink: PropTypes.string,
  snackbarMessage: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  submitErrorText: PropTypes.string.isRequired,
};

TenantModal.defaultProps = {
  completeDescription: undefined,
  descriptionPreText: undefined,
  initialNameValueTransformer: (name) => name,
  initialOrgCodeValueTransformer: (orgCode) => orgCode,
  isDialogOpen: false,
  isSnackbarOpen: false,
  tenantId: undefined,
  setIsSnackbarOpen: () => {},
  snackbarLink: undefined,
  snackbarMessage: undefined,
};

export default TenantModal;
