import { Paper } from '@mui/material';
import styled from 'styled-components';

import { appBarPadding, bodyPaperMarginTop } from '../theme';

export default styled(Paper).attrs(() => ({
  variant: 'outlined',
  square: true,
}))`
  ${({ $shouldHideTopMargin }) => !$shouldHideTopMargin && `margin-top: ${bodyPaperMarginTop};`}
  padding: ${appBarPadding};
`;
