import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useAccessToken from '../hooks/use-access-token';
import { FETCH_TENANTS_CACHE_KEY } from './constants';
import { fetchTenants } from './tenants';

const useTenants = () => {
  const accessToken = useAccessToken();

  const { data, isError, isLoading, refetch } = useQuery(
    [FETCH_TENANTS_CACHE_KEY],
    () => fetchTenants({ accessToken }),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
    },
  );

  const tenantsById = useMemo(
    () =>
      data?.reduce(
        (acc, tenant) => ({
          ...acc,
          [tenant.id]: tenant,
        }),
        {},
      ),
    [data],
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
    tenantsById,
  };
};

export default useTenants;
