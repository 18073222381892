import { Close } from '@mui/icons-material';
import { Alert, Button, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { TRY_AGAIN_TEXT } from '../../constants/validation';
import { FORM_SUBMISSION_ERROR_CLOSE_ICON_TEST_ID } from './constants';

const FormSubmissionErrorAlert = ({ errorText, formId, resetMutate }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  return (
    <Alert
      action={
        <>
          <Button color='inherit' form={formId} size='small' type='submit'>
            {TRY_AGAIN_TEXT}
          </Button>
          <IconButton
            color='inherit'
            data-testid={FORM_SUBMISSION_ERROR_CLOSE_ICON_TEST_ID}
            onClick={resetMutate}
            size='small'
          >
            <Close fontSize='small' />
          </IconButton>
        </>
      }
      onClose={resetMutate}
      ref={ref}
      severity='error'
    >
      {errorText}
    </Alert>
  );
};

FormSubmissionErrorAlert.propTypes = {
  errorText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  resetMutate: PropTypes.func.isRequired,
};

export default FormSubmissionErrorAlert;
