import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  FETCH_TENANTS_CACHE_KEY,
  FETCH_TENANT_CACHE_KEY,
  TENANTS_PATH,
} from '../shared/api/constants';
import Button from '../shared/components/Button';
import FormSubmissionErrorAlert from '../shared/components/forms/Form-Submission-Error-Alert';
import useAccessToken from '../shared/hooks/use-access-token';
import useSuccessSnackbar from '../shared/hooks/use-success-snackbar';
import { text } from '../shared/theme';
import { deleteTenant } from './api';
import {
  DELETE_TENANT_CANCEL_BUTTON_TEXT,
  DELETE_TENANT_CONFIRM_BUTTON_TEXT,
  DELETE_TENANT_DIALOG_DESCRIPTION,
} from './constants';

const Description = styled(Typography).attrs(() => ({ variant: 'body1' }))`
  color: ${text.primary};
`;

const formId = 'delete-tenant-form';

const DeleteTenant = ({ handleClose, open, tenant }) => {
  const { handleOpenSuccessSnackbarWithMessage, successSnackbarProps } = useSuccessSnackbar();

  const navigate = useNavigate();
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  const { isError, isLoading, mutate, reset } = useMutation(
    () =>
      deleteTenant({
        accessToken,
        tenantId: tenant?.id,
      }),
    {
      onSuccess: () => {
        navigate(TENANTS_PATH, { replace: true });
        queryClient.invalidateQueries(FETCH_TENANTS_CACHE_KEY);
        queryClient.invalidateQueries(FETCH_TENANT_CACHE_KEY);

        handleOpenSuccessSnackbarWithMessage(`Success. ${tenant?.name} has been deleted.`);
        handleClose();
      },
    },
  );

  const handleDelete = (event) => {
    event.preventDefault();

    mutate();
  };

  return (
    <>
      <Snackbar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...successSnackbarProps}
      />
      <Dialog open={open}>
        <DialogTitle>{`Permanently delete ${tenant?.name}?`}</DialogTitle>
        {isError && (
          <FormSubmissionErrorAlert
            errorText={`Failed to delete ${tenant?.name}`}
            formId={formId}
            resetMutate={reset}
          />
        )}
        <DialogContent>
          <Description>{DELETE_TENANT_DIALOG_DESCRIPTION}</Description>
        </DialogContent>
        <DialogActions>
          <form id={formId} onSubmit={handleDelete}>
            <Button onClick={handleClose}>{DELETE_TENANT_CANCEL_BUTTON_TEXT}</Button>
            <Button color='error' isLoading={isLoading} type='submit'>
              {DELETE_TENANT_CONFIRM_BUTTON_TEXT}
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteTenant.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tenant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orgCode: PropTypes.string.isRequired,
  }),
};

DeleteTenant.defaultProps = {
  tenant: undefined,
};

export default DeleteTenant;
