import styled from 'styled-components';

const SvgStopScaling = styled.svg`
  ${({ height, width }) => `
    min-width: ${width}px;
    min-height: ${height}px;
  `}
`;

export default SvgStopScaling;
