import { ArrowDropDown, ArrowDropUp, CallSplit, Check, Language } from '@mui/icons-material';
import { ButtonBase, Popover, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import useTenant from '../shared/api/use-tenant';
import { SkeletonIfLoading } from '../shared/components/Skeleton';
import TooltipOrFragment from '../shared/components/Tooltip-Or-Fragment';
import TenantIcon from '../shared/components/icons/Tenant-Icon';
import { action, common, paperBorder, text, tooltipDelayProps } from '../shared/theme';
import { ellipsis } from '../shared/utils/styles';
import {
  SWITCHER_ALL_TENANTS_DESCRIPTION,
  SWITCHER_ALL_TENANTS_TITLE,
  SWITCHER_BUTTON_TEST_ID,
  SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION,
  SWITCHER_GLOBAL_WORKSPACE_TITLE,
  SWITCHER_LAST_TENANT_ID_STORAGE_KEY,
  SWITCHER_OPTION_TEST_ID,
  SWITCHER_TENANT_CURRENTLY_EDITING,
  SWITCHER_TENANT_NOT_EDITING,
} from './constants';

const backgroundColor = 'rgba(255, 255, 255, 0.2)';
const hoverBackgroundColor = 'rgba(255, 255, 255, .35)';

const switcherButtonSpacing = 1.5;

const widthAndMaxWidth = css`
  max-width: 500px;
  width: 100%;
`;

const StyledSkeletonIfLoading = styled(SkeletonIfLoading)`
  ${widthAndMaxWidth}
`;

const SwitcherButton = styled(Stack).attrs(() => ({
  alignItems: 'center',
  component: ButtonBase,
  direction: 'row',
  justifyContent: 'space-between',
  spacing: switcherButtonSpacing,
}))`
  ${widthAndMaxWidth}
  background-color: ${backgroundColor};
  border-radius: 4px;
  color: ${common.white};
  height: 40px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  justify-content: space-between;

  &:hover {
    background-color: ${hoverBackgroundColor};
  }

  ${({ $isPopoverOpen }) => $isPopoverOpen && `background-color: ${hoverBackgroundColor};`}
`;

const iconSize = 24;

const SwitcherButtonIconAndTextStack = styled(Stack).attrs(() => ({
  alignItems: 'center',
  direction: 'row',
  spacing: switcherButtonSpacing,
}))`
  ${ellipsis}
  max-width: calc(100% - ${iconSize + switcherButtonSpacing * 8}px);
`;

const SwitcherButtonText = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  ${ellipsis}
`;

const OptionTitle = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  color: ${text.primary};
  line-height: 130%;
  ${ellipsis}
`;
const OptionDescription = styled(Typography).attrs(() => ({
  variant: 'caption',
}))`
  color: ${text.secondary};
  ${ellipsis}
`;

const OptionText = styled(Stack)`
  ${ellipsis}
  text-align: left;
`;

const innerSpacing = 2;

const OptionInner = styled(Stack).attrs(() => ({
  alignItems: 'center',
  direction: 'row',
  justifyContent: 'space-between',
  spacing: innerSpacing,
}))`
  max-width: 100%;
  width: 100%;
`;

const IconAndTextStack = styled(Stack).attrs(() => ({
  alignItems: 'center',
  direction: 'row',
  spacing: innerSpacing,
}))`
  max-width: calc(100% - ${iconSize + innerSpacing * 8}px);
`;

const Option = styled(ButtonBase)`
  ${({ $isLast }) => !$isLast && `border-bottom: ${paperBorder};`}
  ${({ $isSelected }) => $isSelected && 'pointer-events: none;'}

  &:hover {
    background-color: ${action.hover};
  }

  justify-content: flex-start;
  padding: 12px;

  max-width: 100%;
`;

const Options = styled(Stack).attrs(() => ({ justifyContent: 'flex-start' }))`
  width: 500px;
`;

const DelayedTooltip = styled(TooltipOrFragment).attrs(() => ({
  ...tooltipDelayProps,
  disableInteractive: true,
}))``;

const Switcher = ({ tenantId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = !!anchorEl;

  const navigate = useNavigate();

  const handleClickSwitcher = (event) => setAnchorEl(event.currentTarget);

  const handleCloseSwitcher = () => setAnchorEl(null);

  const [lastTenantId, setLastTenantId] = useState();

  useEffect(() => {
    const tenantIdFromSessionStorage = sessionStorage.getItem(SWITCHER_LAST_TENANT_ID_STORAGE_KEY);

    const tenantIdFromLocalStorage = localStorage.getItem(SWITCHER_LAST_TENANT_ID_STORAGE_KEY);

    if (tenantId && tenantId !== tenantIdFromSessionStorage) {
      sessionStorage.setItem(SWITCHER_LAST_TENANT_ID_STORAGE_KEY, tenantId);
    }

    if (tenantId && tenantId !== tenantIdFromLocalStorage) {
      localStorage.setItem(SWITCHER_LAST_TENANT_ID_STORAGE_KEY, tenantId);
    }

    setLastTenantId(tenantIdFromSessionStorage || tenantIdFromLocalStorage);
  }, [tenantId]);

  const tenantIdToFetch = tenantId || lastTenantId;

  const { data: tenant, isLoading } = useTenant({
    tenantId: tenantIdToFetch,
  });

  const createClickHandler = (path) => () => {
    handleCloseSwitcher();

    navigate(path);
  };

  const tenantOption = {
    description: tenantId ? SWITCHER_TENANT_CURRENTLY_EDITING : SWITCHER_TENANT_NOT_EDITING,
    isSelected: tenantId,
    onClick: createClickHandler(`/tenants/${tenant?.id}/tenant-setup/tenant-details`),
    tenantIcon: 'waves',
    title: tenant?.name,
    tooltip: tenant?.name,
  };

  const allTenantsOption = {
    description: SWITCHER_ALL_TENANTS_DESCRIPTION,
    Icon: CallSplit,
    onClick: createClickHandler(`/tenants`),
    title: SWITCHER_ALL_TENANTS_TITLE,
  };

  const globalWorkspaceOption = {
    description: SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION,
    isSelected: !tenantId,
    Icon: Language,
    onClick: createClickHandler(`/tenants`),
    title: SWITCHER_GLOBAL_WORKSPACE_TITLE,
  };

  const options = [tenant ? tenantOption : allTenantsOption, globalWorkspaceOption];

  const currentOption = tenantId ? tenantOption : globalWorkspaceOption;

  const CurrentIcon = currentOption?.Icon;

  return (
    <StyledSkeletonIfLoading isLoading={isLoading}>
      <SwitcherButton
        $isPopoverOpen={isPopoverOpen}
        data-testid={SWITCHER_BUTTON_TEST_ID}
        onClick={handleClickSwitcher}
      >
        <SwitcherButtonIconAndTextStack>
          {currentOption?.tenantIcon ? (
            <TenantIcon color={common.white} name={currentOption?.tenantIcon} />
          ) : (
            CurrentIcon && <CurrentIcon />
          )}
          <DelayedTooltip title={currentOption?.title}>
            <SwitcherButtonText>{currentOption?.title}</SwitcherButtonText>
          </DelayedTooltip>
        </SwitcherButtonIconAndTextStack>
        {isPopoverOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </SwitcherButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleCloseSwitcher}
        open={isPopoverOpen}
      >
        <Options>
          {options?.map(
            ({ description, Icon, isSelected, onClick, tenantIcon, title, tooltip }, index) => (
              <Option
                $isLast={options?.length - 1 === index}
                $isSelected={isSelected}
                data-testid={SWITCHER_OPTION_TEST_ID}
                key={title}
                onClick={onClick}
              >
                <OptionInner>
                  <IconAndTextStack>
                    {tenantIcon ? <TenantIcon name={tenantIcon} /> : <Icon color='action' />}
                    <OptionText>
                      <DelayedTooltip title={tooltip}>
                        <OptionTitle>{title}</OptionTitle>
                      </DelayedTooltip>
                      <OptionDescription>{description}</OptionDescription>
                    </OptionText>
                  </IconAndTextStack>
                  {isSelected && <Check color='action' />}
                </OptionInner>
              </Option>
            ),
          )}
        </Options>
      </Popover>
    </StyledSkeletonIfLoading>
  );
};

Switcher.propTypes = {
  tenantId: PropTypes.string,
};

Switcher.defaultProps = {
  tenantId: undefined,
};

export default Switcher;
