import { APPLICATION_JSON_ACCEPT } from '../constants/requests';
import { API_BASE_URL, buildApiPath } from './common';
import {
  DELETE_TENANT_ERROR_TEXT,
  EDIT_TENANT_ERROR_TEXT,
  FETCH_TENANT_ERROR_TEXT,
  TENANTS_PATH,
} from './constants';

export const fetchTenant = async ({ accessToken, tenantId }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}/${tenantId}`, {
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(FETCH_TENANT_ERROR_TEXT);
  }

  return await response.json();
};

export const patchTenant = async ({ accessToken, body, tenantId }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}/${tenantId}`, {
    body: JSON.stringify(body),
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': APPLICATION_JSON_ACCEPT,
    },
    method: 'PATCH',
  });

  if (!response.ok) {
    throw new Error(EDIT_TENANT_ERROR_TEXT);
  }
};

export const deleteTenant = async ({ accessToken, tenantId }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}/${tenantId}`, {
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'DELETE',
  });

  if (!response.ok) {
    throw new Error(DELETE_TENANT_ERROR_TEXT);
  }
};

export const putTenant = async ({ accessToken, body, tenantId }) => {
  const response = await fetch(`${buildApiPath(API_BASE_URL, TENANTS_PATH)}/${tenantId}`, {
    body: JSON.stringify(body),
    headers: {
      Accept: APPLICATION_JSON_ACCEPT,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': APPLICATION_JSON_ACCEPT,
    },
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error(EDIT_TENANT_ERROR_TEXT);
  }
};
