import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { backgroundColor, bodyPaperMarginTop, common, paperBorder, text } from '../theme';
import { HIDE_DETAILS_BUTTON_TEXT, SHOW_DETAILS_BUTTON_TEXT } from './constants';

export const DrawerContainer = styled.div`
  border: ${paperBorder};
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;

  background-color: ${({ $whiteBackground }) => ($whiteBackground ? 'white' : backgroundColor)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 368px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: 520px;
  }
`;

export const DrawerInnerContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 24px;
`;

export const ActionBar = styled(Stack).attrs(() => ({
  direction: 'row',
  justifyContent: 'space-between',
}))`
  background-color: ${common.white};
  border-top: ${paperBorder};
  bottom: 0;
  padding: 12px;
  position: sticky;
`;

export const Content = styled(Stack).attrs(() => ({ direction: 'row' }))`
  border: ${paperBorder};
  border-bottom: none;
  box-sizing: border-box;
  height: calc(100% - ${bodyPaperMarginTop});
`;

export const LeftContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

export const DetailsButton = ({ isOpen, onClick, ...rest }) => (
  <Button
    variant='outlined'
    size='small'
    endIcon={isOpen ? <ChevronRight /> : <ChevronLeft />}
    onClick={onClick}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {isOpen ? HIDE_DETAILS_BUTTON_TEXT : SHOW_DETAILS_BUTTON_TEXT}
  </Button>
);

DetailsButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SectionTitle = styled(Typography).attrs(() => ({
  variant: 'body2',
}))`
  color: ${text.secondary};
`;

export const SectionSubTitle = styled(Typography).attrs(() => ({
  variant: 'body2',
}))`
  color: ${text.disabled};
`;

export const SectionTitleStack = styled(Stack).attrs(() => ({
  direction: 'row',
  justifyContent: 'space-between',
}))``;
