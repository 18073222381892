import { useQuery } from 'react-query';

import useAccessToken from '../hooks/use-access-token';
import { FETCH_TENANT_CACHE_KEY } from './constants';
import { fetchTenant } from './tenant';

const useTenant = ({ tenantId }) => {
  const accessToken = useAccessToken();

  const { data, isError, isLoading, refetch } = useQuery(
    [FETCH_TENANT_CACHE_KEY, tenantId],
    () => fetchTenant({ accessToken, tenantId }),
    {
      enabled: !!(accessToken && tenantId),
    },
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};

export default useTenant;
