import { DonutLarge, ShowChart } from '@mui/icons-material';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import PageWithSideNavigation from '../shared/components/Page-With-Side-Navigation';
import {
  TENANT_SETUP_DRIVERS_PATH,
  TENANT_SETUP_LABELS_PATH,
  TENANT_SETUP_SETTINGS_PATH,
  TENANT_SETUP_TENANT_DETAILS_PATH,
  TENANT_SETUP_UTILITIES_PATH,
  addTenantRouteParam,
} from '../shared/constants/routes';
import {
  TENANT_SETUP_NAVIGATION_DRIVERS_LABEL,
  TENANT_SETUP_NAVIGATION_EDIT_TENANT_DETAILS_TITLE,
  TENANT_SETUP_NAVIGATION_EDIT_TENANT_DRIVERS_TITLE,
  TENANT_SETUP_NAVIGATION_EDIT_TENANT_LABELS_TITLE,
  TENANT_SETUP_NAVIGATION_EDIT_TENANT_SETTINGS_TITLE,
  TENANT_SETUP_NAVIGATION_EDIT_TENANT_UTILITIES_TITLE,
  TENANT_SETUP_NAVIGATION_LABELS_LABEL,
  TENANT_SETUP_NAVIGATION_SETTINGS_LABEL,
  TENANT_SETUP_NAVIGATION_TENANT_DETAILS_LABEL,
  TENANT_SETUP_NAVIGATION_TENANT_SETUP_TITLE,
  TENANT_SETUP_NAVIGATION_UTILITIES_LABEL,
} from './constants';

const TenantSetup = () => {
  const { tenant: tenantId } = useParams();

  const tenantDetailsRoutePath = addTenantRouteParam(TENANT_SETUP_TENANT_DETAILS_PATH);
  const tenantDriversRoutePath = addTenantRouteParam(TENANT_SETUP_DRIVERS_PATH);
  const tenantUtilitiesRoutePath = addTenantRouteParam(TENANT_SETUP_UTILITIES_PATH);
  const tenantLabelsRoutePath = addTenantRouteParam(TENANT_SETUP_LABELS_PATH);
  const tenantSettingsRoutePath = addTenantRouteParam(TENANT_SETUP_SETTINGS_PATH);

  const tenantSetupNavigationLinks = [
    {
      label: TENANT_SETUP_NAVIGATION_TENANT_DETAILS_LABEL,
      Icon: DonutLarge,
      to: `/tenants/${tenantId}${TENANT_SETUP_TENANT_DETAILS_PATH}`,
    },
    {
      label: TENANT_SETUP_NAVIGATION_DRIVERS_LABEL,
      Icon: ShowChart,
      to: `/tenants/${tenantId}${TENANT_SETUP_DRIVERS_PATH}`,
    },
    {
      label: TENANT_SETUP_NAVIGATION_UTILITIES_LABEL,
      Icon: DonutLarge,
      to: `/tenants/${tenantId}${TENANT_SETUP_UTILITIES_PATH}`,
    },
    {
      label: TENANT_SETUP_NAVIGATION_LABELS_LABEL,
      Icon: ShowChart,
      to: `/tenants/${tenantId}${TENANT_SETUP_LABELS_PATH}`,
    },
    {
      label: TENANT_SETUP_NAVIGATION_SETTINGS_LABEL,
      Icon: DonutLarge,
      to: `/tenants/${tenantId}${TENANT_SETUP_SETTINGS_PATH}`,
    },
  ];

  return (
    <PageWithSideNavigation
      navigationItems={[
        {
          links: tenantSetupNavigationLinks,
          subheader: TENANT_SETUP_NAVIGATION_TENANT_SETUP_TITLE,
        },
      ]}
      titleItems={[
        {
          path: tenantDetailsRoutePath,
          title: TENANT_SETUP_NAVIGATION_EDIT_TENANT_DETAILS_TITLE,
        },
        {
          path: tenantDriversRoutePath,
          title: TENANT_SETUP_NAVIGATION_EDIT_TENANT_DRIVERS_TITLE,
        },
        {
          path: tenantUtilitiesRoutePath,
          title: TENANT_SETUP_NAVIGATION_EDIT_TENANT_UTILITIES_TITLE,
        },
        {
          path: tenantLabelsRoutePath,
          title: TENANT_SETUP_NAVIGATION_EDIT_TENANT_LABELS_TITLE,
        },
        {
          path: tenantSettingsRoutePath,
          title: TENANT_SETUP_NAVIGATION_EDIT_TENANT_SETTINGS_TITLE,
        },
      ]}
    >
      <Outlet />
    </PageWithSideNavigation>
  );
};

export default TenantSetup;
