import {
  AllInclusive,
  Bolt,
  EnergySavingsLeafOutlined,
  Engineering,
  FamilyRestroom,
  Gavel,
  Landscape,
  Lightbulb,
  LocalDrink,
  Looks,
  Map,
  MonetizationOn,
  Public,
  Savings,
  Science,
  ShowChart,
  ThunderstormOutlined,
  TimerOutlined,
  VolunteerActivism,
  WaterDamage,
  Waves,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';

import { iconColors } from '../../theme';
import {
  ALL_INCLUSIVE,
  BOLT,
  ENERGY_SAVING,
  ENGINEERING,
  FAMILY_RESTROOM,
  GAVEL,
  LANDSCAPE,
  LIGHT_BULB,
  LOCAL_DRINK,
  LOOKS,
  MAP,
  MONETIZATION_ON,
  PUBLIC,
  SAVINGS,
  SCIENCE,
  SHOW_CHART,
  THUNDERSTORM,
  TIMER,
  VOLUNTEER_ACTIVISM,
  WATER_DAMAGE,
  WAVES,
} from './constants';

const { darkBlue, darkRed, green, lightBlue, lightRed, orange, yellow } = iconColors;

const nameToMuiIconMap = {
  [ALL_INCLUSIVE]: {
    color: darkBlue,
    component: AllInclusive,
  },
  [BOLT]: {
    color: yellow,
    component: Bolt,
  },
  [ENERGY_SAVING]: {
    color: green,
    component: EnergySavingsLeafOutlined,
  },
  [ENGINEERING]: {
    color: orange,
    component: Engineering,
  },
  [FAMILY_RESTROOM]: {
    color: lightRed,
    component: FamilyRestroom,
  },
  [GAVEL]: {
    color: darkRed,
    component: Gavel,
  },
  [LANDSCAPE]: {
    color: green,
    component: Landscape,
  },
  [LIGHT_BULB]: {
    color: yellow,
    component: Lightbulb,
  },
  [LOCAL_DRINK]: {
    color: lightBlue,
    component: LocalDrink,
  },
  [LOOKS]: {
    color: lightRed,
    component: Looks,
  },
  [MAP]: {
    color: lightBlue,
    component: Map,
  },
  [MONETIZATION_ON]: {
    color: green,
    component: MonetizationOn,
  },
  [PUBLIC]: {
    color: darkBlue,
    component: Public,
  },
  [SAVINGS]: {
    color: darkRed,
    component: Savings,
  },
  [SHOW_CHART]: {
    color: darkRed,
    component: ShowChart,
  },
  [SCIENCE]: {
    color: orange,
    component: Science,
  },
  [TIMER]: {
    color: lightRed,
    component: TimerOutlined,
  },
  [THUNDERSTORM]: {
    color: orange,
    component: ThunderstormOutlined,
  },
  [VOLUNTEER_ACTIVISM]: {
    color: yellow,
    component: VolunteerActivism,
  },
  [WATER_DAMAGE]: {
    color: lightBlue,
    component: WaterDamage,
  },
  [WAVES]: {
    color: darkBlue,
    component: Waves,
  },
};

const TenantIcon = ({ color, name, ...props }) => {
  const muiIconProps = nameToMuiIconMap[name];
  const MuiIcon = muiIconProps?.component;

  return (
    <MuiIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{ color: color || muiIconProps.color }}
    />
  );
};

TenantIcon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
};

TenantIcon.defaultProps = {
  color: undefined,
  name: undefined,
};

export default TenantIcon;
