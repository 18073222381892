import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { text } from '../../theme';

export const Section = styled(Stack).attrs(() => ({ spacing: 3 }))`
  width: 100%;
`;
export const SectionHeader = styled(Stack).attrs(() => ({ spacing: 1 }))``;
export const SectionTitle = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  color: ${text.secondary};
`;
export const SectionDescription = styled(Typography).attrs(() => ({
  variant: 'body2',
}))`
  color: ${text.primary};
`;

export const FormStack = styled(Stack).attrs(() => ({
  spacing: 7,
}))`
  max-width: 720px;
  width: 100%;
`;

export const FormStackContainer = styled(Stack).attrs(() => ({
  alignItems: 'center',
}))``;
