import { useAuth0 } from '@auth0/auth0-react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  StyledAppBarContainer as AppBarContainer,
  InnerAppBarStack,
  StyledAppBar,
  StyledContainer,
} from '../shared/components/Layout-Components';
import { appBarPadding, appBarPaddingNumber, primary } from '../shared/theme';
import Switcher from './Switcher';
import { APP_BAR_TEST_ID } from './constants';

const iconButtonPadding = 8;

const StyledAppBarContainer = styled(AppBarContainer)`
  padding-left: ${appBarPadding};

  padding-right: ${appBarPaddingNumber - iconButtonPadding}px;
`;

const SwitcherContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  height: 100%;
  padding-left: 16px;
  min-width: min-content;

  .MuiTabs-flexContainer {
    height: 100%;
    justify-content: flex-end;
  }

  .MuiTabs-indicator {
    background-color: ${primary.contrastText};
  }
`;

const Layout = ({ children, tenantId }) => {
  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);

  const {
    logout,
    user: { picture },
  } = useAuth0();

  const handleOpenUserMenu = ({ currentTarget }) => setUserMenuAnchorElement(currentTarget);

  const handleCloseUserMenu = () => setUserMenuAnchorElement(null);

  const noTenantSelectedTabs = [
    {
      label: 'Tenants',
      path: '/tenants',
      to: '/tenants',
    },
  ];

  const tabs = tenantId ? [] : noTenantSelectedTabs;

  const userMenuItems = [
    {
      action: () => logout({ returnTo: `${window.location.origin}` }),
      Icon: LogoutIcon,
      label: 'Sign Out',
    },
  ];

  return (
    <>
      <StyledAppBar $isGlobalWorkspace={!tenantId} data-testid={APP_BAR_TEST_ID} position='sticky'>
        <StyledAppBarContainer>
          <InnerAppBarStack justifyContent={!tenantId ? 'space-between' : undefined}>
            <SwitcherContainer>
              <Switcher tenantId={tenantId} />
            </SwitcherContainer>
            <StyledTabs textColor='inherit' value={'/tenants'}>
              {tabs.map(({ label, to }) => (
                <Tab component={Link} key={to} to={to} label={label} value={to} />
              ))}
            </StyledTabs>
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar src={picture} />
            </IconButton>
            <Menu
              anchorEl={userMenuAnchorElement}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              onClose={handleCloseUserMenu}
              open={!!userMenuAnchorElement}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {userMenuItems.map(({ action, label, Icon, path }) => {
                const linkProps = {
                  component: Link,
                  onClick: handleCloseUserMenu,
                  to: path,
                };

                const actionProps = {
                  onClick: action,
                };

                return (
                  <MenuItem
                    key={label}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(action ? actionProps : linkProps)}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </InnerAppBarStack>
        </StyledAppBarContainer>
      </StyledAppBar>
      <StyledContainer>{children}</StyledContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tenantId: PropTypes.string,
};

Layout.defaultProps = {
  tenantId: undefined,
};

export default Layout;
