export const TENANT_SETUP_NAVIGATION_TENANT_SETUP_TITLE = 'Tenant Setup';

export const TENANT_SETUP_NAVIGATION_TENANT_DETAILS_LABEL = 'Tenant Details';
export const TENANT_SETUP_NAVIGATION_EDIT_TENANT_DETAILS_TITLE = 'Edit Tenant Details';
export const TENANT_SETUP_NAVIGATION_LABELS_LABEL = 'Labels';
export const TENANT_SETUP_NAVIGATION_EDIT_TENANT_LABELS_TITLE = 'Edit Labels';
export const TENANT_SETUP_NAVIGATION_DRIVERS_LABEL = 'Drivers';
export const TENANT_SETUP_NAVIGATION_EDIT_TENANT_DRIVERS_TITLE = 'Edit Drivers';
export const TENANT_SETUP_NAVIGATION_UTILITIES_LABEL = 'Utilities';
export const TENANT_SETUP_NAVIGATION_EDIT_TENANT_UTILITIES_TITLE = 'Edit Utilities';
export const TENANT_SETUP_NAVIGATION_SETTINGS_LABEL = 'Settings';
export const TENANT_SETUP_NAVIGATION_EDIT_TENANT_SETTINGS_TITLE = 'Edit Settings';
