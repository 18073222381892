import { Skeleton as MuiSkeleton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SKELETON_LOADER_TEST_ID } from './constants';

export const Skeleton = styled(MuiSkeleton).attrs(() => ({
  'data-testid': SKELETON_LOADER_TEST_ID,
}))``;

export const InvisibleLoader = styled(Skeleton)`
  display: none;
`;

export const SkeletonIfLoading = ({ children, className, isLoading, variant, width }) => {
  if (isLoading) {
    return (
      <Skeleton className={className} variant={variant} width={width}>
        {children}
      </Skeleton>
    );
  }

  return children;
};

SkeletonIfLoading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  width: PropTypes.string,
};

SkeletonIfLoading.defaultProps = {
  children: null,
  className: undefined,
  variant: 'rectangular',
  width: undefined,
};

export const TextSkeletonIfLoading = ({ children, isLoading, width }) => {
  if (isLoading) {
    return <Skeleton variant='text' width={width} />;
  }

  return children;
};

TextSkeletonIfLoading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

TextSkeletonIfLoading.defaultProps = {
  children: null,
  width: undefined,
};
